import React, { createContext, useContext, useState } from "react";

const BeurzenContext = createContext(null);

export const useBeurzen = () => useContext(BeurzenContext);

export const BeurzenProvider = ({ children }) => {
	const [beurzen, setBeurzen] = useState([]);

	return (
		<BeurzenContext.Provider value={{ beurzen, setBeurzen }}>
			{children}
		</BeurzenContext.Provider>
	);
};
