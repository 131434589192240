import { onAuthStateChanged } from "firebase/auth";
import { Timestamp, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BeursList from "../components/Common/BeursList";
import CheckBoxRow from "../components/Common/CheckBoxRow";
import FileUpload from "../components/Common/FileUpload";
import Header from "../components/Common/Header";
import { useBeurzen } from "../context/BeurzenContext";
import { auth, db } from "../firebase/config";
import "../styles/pages/nieuwproject.css";
import { createAlert } from "../utils/alerts";
import { getBeurzen } from "../utils/firestore-data";
function NieuwProject() {
	const location = useLocation();

	const projectID = String(Date.now());

	const { beurzen, setBeurzen } = useBeurzen();

	const navigate = useNavigate();

	let alreadyLoadedBeurs = false;

	const [selectedProject, setSelectedProject] = useState("");
	const [selectedBeurs, setSelectedBeurs] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0);

		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (!user) {
				navigate("/login");
			}
		});

		const fetchBeurzen = async () => {
			if (beurzen.length > 0) return;

			try {
				const beurzenData = await getBeurzen();

				setBeurzen(beurzenData);
			} catch (error) {
				console.error("Failed to fetch beurzen:", error);
				navigate("/login");
			}
		};

		fetchBeurzen();

		if (location != null && !alreadyLoadedBeurs) {
			alreadyLoadedBeurs = true;
			try {
				const beursData = location.state;

				let beursIndex = beurzen.findIndex(
					(element) =>
						element["title"] == beursData["title"] &&
						element["location"] == beursData["location"] &&
						element["startdate"] == beursData["startdate"] &&
						element["enddate"] == beursData["enddate"]
				);
				selectBeurs(beursIndex);
			} catch (e) {
				console.log(e);
			}
		}

		return () => {
			unsubscribe();
		};
	}, [navigate]);

	function formatDate(inputDate) {
		// Create a new Date object
		var date = new Date(inputDate);

		// Get the day, month, and year
		var day = String(date.getDate()).padStart(2, "0");
		var month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
		var year = date.getFullYear();

		// Return the formatted string
		return day + "/" + month + "/" + year;
	}

	let submittingForm = false;

	async function submitForm() {
		if (submittingForm) return;

		const contactVal = document.getElementById("contact").value;
		const nameVal = document.getElementById("beursnaam").value;
		const startDateVal = document.getElementById("startDate").value;
		const endDateVal = document.getElementById("endDate").value;
		const locationVal = document.getElementById("location").value;
		const standnummerVal = document.getElementById("standnummer").value;
		const opleverdagVal = document.getElementById("opleverdag").value;
		const statusVal = document.getElementById("status").selectedIndex;
		const detaileringVal = document.getElementById("detailering").value;

		let checkboxMapping = {};
		let filesMapping = {};
		let notesMapping = {};

		if (contactVal == "") {
			createAlert("Vul een contact in", false);
			return;
		} else if (nameVal == "") {
			createAlert("Vul een beurs in", false);
			return;
		} else if (startDateVal == "") {
			createAlert("Vul een start datum in", false);
			return;
		} else if (endDateVal == "") {
			createAlert("Vul een eind datum in", false);
			return;
		}

		submittingForm = true;

		const id = toast.loading("Project wordt toegevoegd", {
			position: "bottom-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			transition: Bounce,
		});

		document.querySelectorAll(`table:nth-child(1) > tbody > tr`).forEach((e) => {
			if (
				(e.childElementCount == 4 || e.childElementCount == 3) &&
				(e.children[2].childElementCount == 2 ||
					e.children[2].childElementCount == 1)
			) {
				const uploadPresent = e.children[2].childElementCount == 2;
				let selected = 0;

				if (e.children[1].children[0].children[2].checked) {
					selected = 1;
				} else if (e.children[1].children[0].children[4].checked) {
					selected = 2;
				}

				if (uploadPresent) {
					filesMapping[e.children[0].innerHTML] =
						e.children[2].children[0].getAttribute("ref");
				} else {
					filesMapping[e.children[0].innerHTML] = null;
				}

				checkboxMapping[e.children[0].innerHTML] = selected;

				if (uploadPresent) {
					notesMapping[e.children[0].innerHTML] = e.children[3].children[0].value;
				} else {
					notesMapping[e.children[0].innerHTML] = e.children[2].children[0].value;
				}
			}
		});

		// get current beurzen
		const date = new Date();
		const currentYear = date.getFullYear();
		let foundBeurs = false;
		let beursId;
		let bewerkBeurzenData;
		let correctBeurs = false;

		// Loop through 7 years to find the matching beurs
		for (let i = 0; i < 7; i++) {
			if (!foundBeurs) {
				const docRef = doc(db, "beurzen", (currentYear + i).toString());
				const docSnapshot = await getDoc(docRef);
				const data = docSnapshot.data();

				if (data !== undefined) {
					for (const [key, value] of Object.entries(data)) {
						if (
							!foundBeurs &&
							(value["tt"] == nameVal ||
								value["tt"] == nameVal.replaceAll("&", "&amp;"))
						) {
							beursId = key;
							bewerkBeurzenData = data[key];
							foundBeurs = true;
							correctBeurs = true;
						}
					}
				}
			}
		}

		if (correctBeurs) {
			// Add new project to 'projecten' collection
			await setDoc(doc(db, "projecten", projectID), {
				contact: contactVal,
				name: nameVal,
				startDate: startDateVal,
				endDate: endDateVal,
				location: locationVal ?? "Niet vermeld",
				standnummer: standnummerVal,
				opleverdag: opleverdagVal,
				status: statusVal,
				detailering: detaileringVal,
				checkboxes: checkboxMapping,
				files: filesMapping,
				notes: notesMapping,
			});

			// Update the 'beurzen' entry
			let currentProjects = [];
			try {
				currentProjects = bewerkBeurzenData["pr"];
			} catch (e) {
				//
			}

			if (!currentProjects) {
				currentProjects = [];
			}

			currentProjects.push({
				naam: contactVal.split("@")[0],
				id: projectID.toString(),
				status: statusVal,
			});

			bewerkBeurzenData["pr"] = currentProjects;
			bewerkBeurzenData["sd"] = Timestamp.fromDate(new Date(startDateVal));
			bewerkBeurzenData["ed"] = Timestamp.fromDate(new Date(endDateVal));
			bewerkBeurzenData["loc"] = locationVal;

			const docRef = doc(
				db,
				"beurzen",
				new Date(startDateVal).getFullYear().toString()
			);

			await updateDoc(docRef, {
				[beursId]: bewerkBeurzenData,
			});

			setBeurzen(await getBeurzen());

			navigate("/planning");

			toast.update(id, {
				render: "Succesvol toegevoegd!",
				type: "success",
				isLoading: false,
			});

			setTimeout(() => {
				toast.dismiss(id);
			}, 2000);
		} else {
			toast.update(id, {
				render: "Beurs niet gevonden",
				type: "error",
				isLoading: false,
			});

			setTimeout(() => {
				toast.dismiss(id);
			}, 2000);
		}

		submittingForm = false;
	}

	const selectBeurs = (i) => {
		const beursTitle = beurzen[i]["title"].replaceAll("&amp;", "&");

		document.getElementById("beursnaam").value = beursTitle;

		if (beurzen[i]["location"] != "Niet vermeld") {
			document.getElementById("location").value = beurzen[i]["location"];
		} else {
			document.getElementById("location").value = "";
		}

		const startDate = beurzen[i]["startdate"];
		const endDate = beurzen[i]["enddate"];

		document.getElementById("startDate").value = parseDateString(startDate);
		document.getElementById("endDate").value = parseDateString(endDate);
		createAlert(`${beursTitle} geselecteerd!`, false, true);
	};

	function parseDateString(dateString) {
		const [day, month, year] = dateString.split("/");
		return `${year}-${month}-${day}`;
	}

	const invulvelden = {
		Startfase: [
			"Plattegrond (puttenplan)",
			"Op- en afbouwtijden",
			"Bouwregels",
			"Inlogcodes",
			"Briefing and brandguide",
		],
		"Productie en voorbereiding": [
			"Risk assessment en method",
			"Standkeuring",
			"Op- en afbouwpassen",
			"Tijdsloten en inrijkaart",
			"Systeemstand",
			"Elektra",
			"Tuigen en takels",
			"Water aan- en afvoer",
			"Vloer",
			"Meubels",
			"Verlichting",
			"Audio en Visueel",
			"Grafisch werk",
			"Content",
		],
		Facturatie: ["1e termijn", "2e termijn"],
		Ontwerpfase: [
			"Def ontwerp",
			"Def offerte",
			"Def tekening",
			"Opdrachtbevestiging",
		],
		Evaluatie: ["Klant", "Beurs", "Locatie", "Diversen"],
	};

	let indexCount = 0;

	return (
		<div id="nieuw-project">
			<Header title={"Nieuw project"} />
			<main>
				<column>
					<row id="main-row">
						<BeursList
							beurzen={beurzen}
							selectedBeurs={selectedBeurs}
							selectedProject={selectedProject}
						></BeursList>
						<column id="main-right">
							<form>
								<row>
									<column id="input-column">
										<label id="big-height" class="required">
											Contact
										</label>
										<input placeholder="Vul contact in" id="contact" required=""></input>
										<row class="inputs">
											<column>
												<label class="required">
													Beursnaam
													<autocomplete>
														{" "}
														<suggestion class="focus">Fespa Global Print Expo</suggestion>
														<suggestion>Aqua Nederland</suggestion>
														<suggestion>Beurs eigen huis voorjaar</suggestion>
														<suggestion>workspace</suggestion>
														<suggestion>Zorg &amp; food</suggestion>
														<suggestion>FireSafety &amp; Security Event</suggestion>
														<suggestion>FIBO</suggestion>
														<suggestion>wac congress</suggestion>
														<suggestion>World hydrogen congress</suggestion>
														<suggestion>gezond &amp; zeker</suggestion>
														<suggestion>Efort Congress</suggestion>
														<suggestion>podcastshow</suggestion>
														<suggestion>PLMA</suggestion>
														<suggestion>Maritime industry</suggestion>
														<suggestion>Greentech</suggestion>
														<suggestion>Provada</suggestion>
														<suggestion>Intersolar</suggestion>
														<suggestion>EUROBIKE</suggestion>
														<suggestion>cigre paris</suggestion>
														<suggestion>SMM</suggestion>
														<suggestion>Ssiem congres porto</suggestion>
														<suggestion>Maison &amp; objet</suggestion>
														<suggestion>Galabau</suggestion>
														<suggestion>Ibc</suggestion>
														<suggestion>Iaa hannover</suggestion>
														<suggestion>Kunststoffenbeurs</suggestion>
														<suggestion>WINDENERGY HAMBURG</suggestion>
														<suggestion>Vakbeurs openbare ruimte</suggestion>
														<suggestion>Onderwijsbeurs Zuid</suggestion>
														<suggestion>Vakbeurs foodspecialiteiten</suggestion>
														<suggestion>Vt wonen &amp;designbeurs</suggestion>
														<suggestion>Onderwijsbeurs Noordoost</suggestion>
														<suggestion>expopharm</suggestion>
														<suggestion>Beurs eigen huis najaar</suggestion>
														<suggestion>Prefab</suggestion>
														<suggestion>EANM congress</suggestion>
														<suggestion>Prowood</suggestion>
														<suggestion>Expovet</suggestion>
														<suggestion>Meubelbeurs Brussel</suggestion>
														<suggestion>Contacta 2024</suggestion>
														<suggestion>FILTECH</suggestion>
														<suggestion>Horeca Expo</suggestion>
														<suggestion>Fi Europe </suggestion>
														<suggestion>Studiekeuzebeurs west</suggestion>
													</autocomplete>
												</label>
												<input
													class="autocomplete"
													placeholder="Vul beursnaam in"
													id="beursnaam"
													autocomplete="off"
													required=""
												></input>
											</column>
											<column>
												<label class="required">Locatie</label>
												<input
													placeholder="Vul Locatie in"
													id="location"
													required=""
												></input>
											</column>
										</row>

										<row class="inputs">
											<column>
												<label class="required">Beurs begin datum</label>
												<input
													placeholder="Vul begin datum in"
													type="date"
													id="startDate"
													required=""
												></input>
											</column>

											<column>
												<label class="required">Beurs eind datum</label>
												<input
													placeholder="Vul eind datum in"
													type="date"
													id="endDate"
													required=""
												></input>
											</column>
										</row>

										<row class="inputs">
											<column>
												<label class="required">Opleverdag</label>
												<input
													placeholder="Vul Opleverdag in"
													type="date"
													id="opleverdag"
													required=""
												></input>
											</column>

											<column>
												<label class="required">Hal/standnummer</label>
												<input
													placeholder="Vul hal of standnummer in"
													id="standnummer"
													required=""
												></input>
											</column>
										</row>

										<label class="required">Status</label>
										<stack>
											<select
												name="status"
												id="status"
												placeholder="Vul Status in"
												onmousedown="selectOnClick(this)"
												required=""
											>
												<option value="Onderhanden">Onderhanden</option>
												<option value="Opgeleverd">Opgeleverd</option>
												<option value="Verloren">Verloren</option>
											</select>
											<img
												src="assets/chevron-right.svg"
												height="13px"
												width="13px"
												class="overlay"
											></img>
										</stack>
									</column>
									<column class="list margin-top">
										<item class="title">Geschiedenis</item>
										<p>Geen geschiedenis beschikbaar.</p>
									</column>
									<column class="list margin-top">
										<item class="title">Bijzonderheden</item>
										<item>Geen Bijzonderheden</item>
									</column>
								</row>
							</form>
							<row>
								<column id="invulvelden">
									<table>
										<tbody>
											{Object.keys(invulvelden).map((veldKey, index) => {
												const velden = invulvelden[veldKey];

												return Array.from({ length: velden.length + 1 }, (_, index2) => {
													if (index2 == 0) {
														return (
															<tr>
																<td class="collapsible">{veldKey}</td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														);
													} else {
														indexCount += 1;
														if (veldKey == "Evaluatie") {
															return (
																<tr class={`c-${index2 - 1}`}>
																	<td>{velden[index2 - 1]}</td>
																	<CheckBoxRow></CheckBoxRow>
																	<td>
																		<input type="value" class="long"></input>
																	</td>
																</tr>
															);
														} else {
															return (
																<tr class={`c-${index2 - 1}`}>
																	<td>{velden[index2 - 1]}</td>
																	<CheckBoxRow></CheckBoxRow>
																	<FileUpload
																		projectID={projectID}
																		index={indexCount - 1}
																		key={projectID + indexCount}
																	></FileUpload>
																	<td>
																		<input type="value"></input>
																	</td>
																</tr>
															);
														}
													}
												});
											})}
										</tbody>
									</table>
								</column>
								<column class="list">
									<item class="title">Detailering</item>
									<img
										src="assets/print.svg"
										id="detailering-print"
										height="20px"
										width="20px"
										onclick="printDetailering();"
									></img>
									<textarea
										placeholder="Type hier de detailering"
										id="detailering"
									></textarea>
								</column>
							</row>
							<buttons>
								<button id="add-button" onClick={submitForm}>
									<img
										src="assets/plus.svg"
										id="add-icon"
										height="14px"
										width="14px"
									></img>
									Toevoegen
								</button>
							</buttons>
						</column>
					</row>
				</column>
			</main>
		</div>
	);
}

export default NieuwProject;
