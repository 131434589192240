import { get, onDisconnect, onValue, ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useAuth } from "../context/AuthContext";
import { rtdb } from "../firebase/config";

function PagePresence({ pageId }) {
	const [activeUsers, setActiveUsers] = useState([]);
	const { profile } = useAuth();
	const [isDisabled, setIsDisabled] = useState(false); // State to track if inputs should be disabled

	const profileName = profile.username;
	const profileEmail = profile.email;
	const profileUid = profile.uid; // Use uid instead of email as the identifier
	useEffect(() => {
		if (profile.username === "") return;

		const userRef = ref(rtdb, `pages/${pageId}/users/${profileUid}`);
		const usersRef = ref(rtdb, `pages/${pageId}/users`);

		// Function to find and activate the first inactive user
		const activateFirstUser = (usersData) => {
			const usersList = Object.entries(usersData).sort(
				([uidA], [uidB]) => (uidA > uidB ? 1 : -1) // Sort users by their UID for consistent ordering
			);
			const activeUserExists = usersList.some(([uid, user]) => user.active);

			if (!activeUserExists && usersList.length > 0) {
				// No active user, activate the first one in the sorted list
				const [firstUid] = usersList[0];
				const firstUserRef = ref(rtdb, `pages/${pageId}/users/${firstUid}/active`);
				const firstUserDisabledRef = ref(
					rtdb,
					`pages/${pageId}/users/${firstUid}/isDisabled`
				);

				set(firstUserRef, true).then(() => {
					// Ensure the first user is re-enabled after being activated
					set(firstUserDisabledRef, false);
				});
			}
		};

		// Check for existing active users after the page has loaded
		get(usersRef).then((snapshot) => {
			const usersData = snapshot.val();
			if (usersData) {
				const usersList = Object.values(usersData);

				if (usersList.length > 0) {
					setTimeout(() => {
						alert(
							"Er zit al iemand op deze pagina. Je moet wachten tot de ander klaar is."
						);
					}, 100);
					setIsDisabled(true); // Disable inputs for new user
				} else {
					setIsDisabled(false); // Re-enable inputs if no one else is on the page
				}

				// Add user to the list with username, email, and active status
				set(userRef, {
					name: profileName,
					email: profileEmail,
					active: usersList.length === 0, // Set active if this is the first user
					isDisabled: usersList.length > 0, // Disable if other users are present
				});
			} else {
				set(userRef, {
					name: profileName,
					email: profileEmail,
					active: true, // No other users, so this user becomes active
					isDisabled: false, // Enable inputs since this user is the only one
				});
			}

			// Remove user from the list when they disconnect
			onDisconnect(userRef).remove();
		});

		// Listen for changes to the users list
		onValue(usersRef, (snapshot) => {
			const usersData = snapshot.val();
			if (usersData) {
				const usersList = Object.values(usersData);
				setActiveUsers(usersList);

				// Activate the first inactive user if no one is active
				activateFirstUser(usersData);

				if (usersList.length > 1) {
					const activeUser = usersList.find((user) => user.active);
					const activeUserUid = Object.keys(usersData).find(
						(uid) => usersData[uid].active
					);

					// Ensure that the correct user's input is enabled
					if (activeUserUid === profileUid) {
						setIsDisabled(false); // Enable inputs for the active user
					} else {
						setIsDisabled(true); // Disable inputs if this user is not active
					}
				} else {
					// Re-enable inputs if the current user is the only one on the page
					setIsDisabled(false);
				}
			} else {
				setActiveUsers([]);
				setIsDisabled(false); // Re-enable inputs if no one else is on the page
			}
		});

		return () => {
			set(userRef, null); // Remove user when unmounting
		};
	}, [pageId, profileUid, profile, profileName, profileEmail]);

	// Function to disable/enable all input fields on the page
	useEffect(() => {
		const inputFields = document.querySelectorAll("input");
		inputFields.forEach((input) => {
			input.readOnly = isDisabled ? "readonly" : "";
		});

		const fileButtons = document.querySelectorAll(".file-buttons");
		fileButtons.forEach((button) => {
			for (let i = 0; i < button.children.length; i++) {
				if (i != 0) {
					button.children[i].style.pointerEvents = isDisabled ? "none" : "";
				}
			}
		});

		const inputButtons = document.querySelectorAll("input[type='checkbox']");
		inputButtons.forEach((input) => {
			input.disabled = isDisabled;
		});

		document
			.querySelectorAll(".public-DraftEditor-content")
			.forEach((detailering) => {
				detailering.setAttribute("contenteditable", !isDisabled);
			});

		document.querySelectorAll(".file-upload").forEach((detailering) => {
			detailering.style.pointerEvents = isDisabled ? "none" : "";
		});

		document.getElementById("save-button").style.display = isDisabled
			? "none"
			: "flex";
	}, [isDisabled]);

	return (
		<div>
			<row class={`align-end full-width isDisabled && 'disabled-circle}`}>
				{activeUsers.map((user, index) => (
					<div key={index}>
						<usertag data-tooltip-id={user.email} data-tooltip-content={user.email}>
							<div class={`circle ${!user.active ? "disabled-circle" : ""}`}></div>
							{user.name}
						</usertag>
						<Tooltip
							id={user.email}
							place="bottom-center"
							style={{
								fontSize: "12px",
							}}
						/>
					</div>
				))}
			</row>
		</div>
	);
}

export default PagePresence;
