import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeurzenProvider } from "./context/BeurzenContext";
import { ScrollProvider } from "./context/ScrollContext";
import { SelectedDatesProvider } from "./context/SelectedDatesContext";
import BewerkProject from "./pages/BewerkProject";
import LoginPage from "./pages/LoginPage";
import NieuwProject from "./pages/NieuwProject";
import PlanningPage from "./pages/PlanningPage";
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <Router>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
        <ScrollProvider>
          <SelectedDatesProvider>
            <BeurzenProvider>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/planning" element={<PlanningPage />} />
                <Route path="/nieuwproject" element={<NieuwProject />} />
                <Route path="/bewerkproject" element={<BewerkProject />} />
                <Route path="*" element={<Navigate to="/planning" />} />
              </Routes>
            </BeurzenProvider>
          </SelectedDatesProvider>
        </ScrollProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
