import React from "react";
import { Link } from "react-router-dom";
import "../../styles/components/header.css";
import { createAlert } from "../../utils/alerts";

function BeursList({ beurzen, selectedProject, selectedBeurs }) {
	return (
		<column class="list margin-top beurs-list">
			<item class="title">Beurzen</item>
			{beurzen.map((beurs, index) => {
				const projecten = beurzen[index]["projecten"];

				let projectenLength = 0;

				if (projecten !== undefined && projecten !== null) {
					projectenLength = projecten.length;
				}

				{
					return Array.from({ length: 1 + projectenLength }, (_, index2) => {
						if (index2 == 0) {
							return (
								<item key={`main${index}-${index2}`}>
									{beurs.title.replaceAll("&amp;", "&")}
								</item>
							);
						} else {
							const selected =
								selectedProject ===
									projecten[index2 - 1]["naam"].replaceAll("&amp;", "&") &&
								selectedBeurs === beurs.title.replaceAll("&amp;", "&");

							return (
								<Link
									to={`/bewerkproject?projectId=${projecten[index2 - 1]["id"]}`}
									class={(selected && "sub-item selected") || "sub-item"}
									key={projecten[index2 - 1]["id"]}
									onClick={() => {
										createAlert(
											`${projecten[index2 - 1]["naam"].replaceAll(
												"&amp;",
												"&"
											)} geselecteerd!`,
											false,
											true
										);
									}}
								>
									{projecten[index2 - 1]["naam"].replaceAll("&amp;", "&")}
								</Link>
							);
						}
					});
				}
			})}
		</column>
	);
}

export default BeursList;
