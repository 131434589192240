import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyAmIaJzl10GCpS5GZoM7iOs7ea79eSPReY",
	authDomain: "beurs-agenda.firebaseapp.com",
	databaseURL:
		"https://beurs-agenda-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "beurs-agenda",
	storageBucket: "beurs-agenda.appspot.com",
	messagingSenderId: "808460006821",
	appId: "1:808460006821:web:4b583bed0e28c3f829244c",
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const rtdb = getDatabase(app);
export const auth = getAuth(app);
