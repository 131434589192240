import { collection, getDocs, query, where } from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase/config"; // Adjust this path to where you configure Firebase auth

const AuthContext = createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [profile, setProfile] = useState({
		username: "",
		img: "",
		uid: "",
		email: "",
	});
	const [loading, setLoading] = useState(true); // State to manage loading status

	// Check login state and fetch user data
	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setCurrentUser(user);
				await fetchUserData(user);
				console.log("User is logged in");
			} else {
				setCurrentUser(null);
				setProfile({
					username: "",
					img: "",
					uid: "",
					email: "",
				});
				console.log("User is not logged in");
			}
			setLoading(false); // Set loading to false after checking user status
		});

		// Clean up the subscription
		return unsubscribe;
	}, []);

	const fetchUserData = async (user) => {
		// Early exit if profile already has username or image
		if (profile.username !== "" || profile.img !== "") return;

		// Create a query to find the user document by uid field
		const usersCollectionRef = collection(db, "users");
		const q = query(usersCollectionRef, where("uid", "==", user.uid));
		const querySnapshot = await getDocs(q);

		if (querySnapshot.empty) {
			return;
		}

		// Assuming only one document will match, adjust as necessary
		const userData = querySnapshot.docs[0].data();
		setProfile({
			username: userData.username,
			img: userData.img,
			uid: user.uid,
			email: user.email,
		});
	};

	const value = {
		currentUser,
		profile,
		isLoggedIn: !!currentUser,
		loading,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
