import React, { createContext, useContext, useState } from "react";

const ScrollContext = createContext(null);

export const ScrollProvider = ({ children }) => {
	const [scrollX, setscrollX] = useState(0);

	const handleScroll = (newY) => {
		setscrollX(newY);
	};

	return (
		<ScrollContext.Provider value={{ scrollX, handleScroll }}>
			{children}
		</ScrollContext.Provider>
	);
};

export const useScroll = () => useContext(ScrollContext);
