import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { React, useRef } from "react";
import { storage } from "../../firebase/config";
import "../../styles/components/header.css";

function FileUpload({ projectID, index }) {
	const inputRef = useRef(null);

	function deleteFile(index, change) {
		const filePath = document
			.querySelectorAll(".file-upload")
			[index].getAttribute("ref");
		const desertRef = ref(storage, filePath);
		// deleteObject(desertRef).then(() => {
		if (!change) {
			document.querySelectorAll(".file-upload")[index].style.display = "flex";
			document.querySelectorAll(".file-upload")[index].children[0].value = "";
			document.querySelectorAll(".file-buttons")[index].style.display = "none";
			document.querySelectorAll(".file-upload")[index].removeAttribute("ref");
		}

		let old_element =
			document.querySelectorAll(".file-upload")[index].children[0];
		let new_element = old_element.cloneNode(true);
		old_element.parentNode.replaceChild(new_element, old_element);

		document
			.querySelectorAll(".file-upload")
			[index].children[0].addEventListener("input", () => {
				onFileUpload(document.querySelectorAll(".file-upload")[index].children[0]);
			});
		// });
	}

	async function onFileUpload(el) {
		let elementIndex = 0;
		let fileUploads = document.querySelectorAll("input[type=file]");

		for (let i = 0; i < fileUploads.length; i++) {
			if (fileUploads[i] == el) {
				elementIndex = i;
			}
		}

		// upload to firebase storage

		const file =
			document.querySelectorAll(".file-upload")[elementIndex].children[0].files[0];
		const fileName = file.name;

		const imageRef = ref(
			storage,
			`projecten/${projectID}/${String(Date.now())}_${fileName}`
		);

		uploadBytes(imageRef, file).then((snapshot) => {
			console.log("Uploaded file");
			document
				.querySelectorAll(".file-upload")
				[elementIndex].setAttribute("ref", snapshot.metadata.fullPath);

			document.querySelectorAll(".file-upload")[elementIndex].style.display =
				"none";
			document.querySelectorAll(".file-buttons")[elementIndex].style.display =
				"flex";

			let old_element =
				document.querySelectorAll(".file-upload")[elementIndex].children[0];
			let new_element = old_element.cloneNode(true);
			old_element.parentNode.replaceChild(new_element, old_element);

			document
				.querySelectorAll(".file-upload")
				[elementIndex].children[0].addEventListener("input", async () => {
					await deleteFile(elementIndex, true);
					onFileUpload(
						document.querySelectorAll(".file-upload")[elementIndex].children[0]
					);
				});
		});
	}

	function viewFile(index) {
		const filePath = document
			.querySelectorAll(".file-upload")
			[index].getAttribute("ref");

		getDownloadURL(ref(storage, filePath)).then((url) => {
			window.open(url, "_blank");
		});
	}

	function changeFile(index) {
		document.querySelectorAll(".file-upload")[index].children[0].click();
	}

	const onInput = () => {
		onFileUpload(inputRef.current);
	};

	return (
		<td>
			<label class="file-upload">
				<input type="file" onInput={onInput} ref={inputRef}></input>
				Upload
				<img src="assets/upload-solid.svg"></img>
			</label>
			<row class="file-buttons">
				<img
					src="assets/eye-solid.svg"
					onClick={() => {
						viewFile(index);
					}}
				></img>
				<img
					src="assets/pen-solid.svg"
					onClick={() => {
						changeFile(index);
					}}
				></img>
				<img
					src="assets/trash-solid.svg"
					onClick={() => {
						if (
							confirm("Weet je zeker dat u dit bestand wilt verwijderen?") == true
						) {
							deleteFile(index, false);
						}
					}}
				></img>
			</row>
		</td>
	);
}

export default FileUpload;
