import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { default as React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import lockIcon from "../assets/lock.svg";
import { auth } from "../firebase/config";
import "../styles/pages/login.css";

function LoginPage() {
	const navigate = useNavigate();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleLogin = async (event) => {
		event.preventDefault();

		const toastId = toast.loading("Proberen in te loggen", {
			position: "bottom-right",
			autoClose: 5000,
			closeOnClick: false,
			draggable: false,
			theme: "light",
			transition: Bounce,
		});

		await signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				navigate("/planning");
				toast.update(toastId, {
					render: "Succesvol ingelogd",
					type: "success",
					isLoading: false,
					progress: undefined,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					autoClose: 3000,
				});
			})
			.catch((error) => {
				toast.update(toastId, {
					render: "Onjuiste gebruikersnaam of wachtwoord",
					type: "warning",
					isLoading: false,
					progress: undefined,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					autoClose: 5000,
				});
			});
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				navigate("/planning"); // Optionally redirect to home if logged in
			}
		});

		return () => {
			unsubscribe();
		};
	}, [navigate]);

	return (
		<div id="login">
			<form onSubmit={handleLogin}>
				<img src={lockIcon} alt="Lock" id="lock" />
				<input
					type="text"
					name="username"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder="Gebruikersnaam"
				/>
				<input
					type="password"
					name="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					placeholder="Wachtwoord"
				/>
				<input type="submit" value="Login" />
			</form>
		</div>
	);
}

export default LoginPage;
