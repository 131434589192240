import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BeursCard from "../components/Common/BeursCard";
import Header from "../components/Common/Header";
import { useBeurzen } from "../context/BeurzenContext";
import { useSelectedDates } from "../context/SelectedDatesContext";
import "../styles/style.css";
// organize-imports-disable-next-line
import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { InView } from "react-intersection-observer";
import { auth } from "../firebase/config";
import "../styles/pages/planning.css";
import { getBeurzen, getSelectedBeursDates } from "../utils/firestore-data";
import { useAuth } from "../context/AuthContext";

export let mouseDown = false;
let globalScroll = 450;

function PlanningPage() {
  const { beurzen, setBeurzen } = useBeurzen();
  const { selectedDates, setSelectedDates } = useSelectedDates();
  const [headerDates, setHeaderDates] = useState([]);
  const [rowDates, setRowDates] = useState([]);
  const [rowDateObjs, setRowDateObjs] = useState([]);

  const navigate = useNavigate();

  useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleMouseDown = (event) => {
      if (event.button === 0) {
        mouseDown = true;
      }
    };

    const handleMouseUp = () => {
      mouseDown = false;
    };

    const fetchBeurzen = async () => {
      if (beurzen.length > 0 && Object.keys(selectedDates).length > 0) return;

      try {
        const beurzenData = await getBeurzen();
        const selectedDatesData = await getSelectedBeursDates();

        setBeurzen(beurzenData);
        setSelectedDates(selectedDatesData);
      } catch (error) {
        console.error("Failed to fetch beurzen:", error);
        navigate("/login");
      }
    };

    const fetchHeaderDates = () => {
      let dates = [];
      let firstDayOfTheWeek = getFirstDayOfWeek();
      const weeks = 52;
      for (let n = 0; n < weeks; n++) {
        const week = getWeekNumber(firstDayOfTheWeek);

        if (week != null) {
          dates.push(`Week ${getWeekNumber(firstDayOfTheWeek)}`);
        }
        firstDayOfTheWeek = firstDayOfTheWeek.addDays(7);
      }
      setHeaderDates(dates);
    };

    const fetchRowDates = () => {
      let currentDate = getFirstDayOfWeek();
      let dates = [];
      let dateObjs = [];
      const endDate = new Date(currentDate);
      endDate.setDate(endDate.getDate() + 364);

      while (currentDate <= endDate) {
        const day = currentDate.toLocaleDateString("nl-NL", {
          day: "numeric",
        });

        const month = currentDate.toLocaleDateString("nl-NL", {
          month: "long",
        });

        dates.push(`${day} ${month}`);
        dateObjs.push(currentDate.toLocaleDateString("en-GB"));

        currentDate = currentDate.addDays(1);
      }

      setRowDateObjs(dateObjs);
      setRowDates(dates);
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      }
    });

    fetchBeurzen();
    fetchHeaderDates();
    fetchRowDates();

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
      unsubscribe();
    };
  }, [setBeurzen, setSelectedDates, navigate, setRowDates]);

  const handleScroll = (e) => {
    if (globalScroll != e) {
      requestAnimationFrame(() => {
        const tbodys = document.querySelectorAll(".date-table > tbody");
        tbodys.forEach((tbody) => {
          tbody.scrollLeft = e;
        });
      });
    }
    globalScroll = e;
  };

  return (
    <div id="planning">
      <Header title={"Planning"} />
      <main>
        {beurzen.map((beurs, index) => (
          <InView key={index} triggerOnce={false} rootMargin={"800px"}>
            {({ inView, ref }) => (
              <div class="fullWidth" ref={ref}>
                <BeursCard
                  visible={inView}
                  data={beurs}
                  headerDates={headerDates}
                  rowDateObjs={rowDateObjs}
                  rowDates={rowDates}
                  handleScroll={handleScroll}
                  globalScroll={globalScroll}
                />
              </div>
            )}
          </InView>
        ))}
      </main>
    </div>
  );
}
export const useScroll = () => useContext(ScrollContext);

function getFirstDayOfWeek() {
  // 10 Week offset
  const today = new Date();

  today.setTime(today.getTime() - 10 * 7 * 24 * 60 * 60 * 1000);

  const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
  let diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust to start of week

  // Check if the new date falls into the previous year
  // if (diff <= 0) {
  // 	// Subtract the remaining days from the end of the previous year
  // 	today.setFullYear(today.getFullYear() - 1);
  // 	today.setMonth(11); // December
  // 	today.setDate(31); // Last day of December
  // 	diff = today.getDate() + diff; // Add remaining days from previous year
  // }

  return new Date(today.setDate(diff));
}

function getWeekNumber(date) {
  // Create a copy of the date object to avoid modifying the original
  var target = new Date(date.valueOf());
  var dayNr = (target.getDay() + 6) % 7; // Adjust day number for ISO (Monday = 0)

  // Set the target date to the Thursday of the current week
  target.setDate(target.getDate() - dayNr + 3);

  // First Thursday of the year
  var firstThursday = new Date(target.getFullYear(), 0, 4);
  firstThursday.setDate(
    firstThursday.getDate() - ((firstThursday.getDay() + 6) % 7) + 3
  );

  // Calculate the week number
  var weekNumber = 1 + Math.round((target - firstThursday) / (7 * 86400000));

  // Ensure week number is at least 1
  if (weekNumber < 1) {
    weekNumber = getWeekNumber(new Date(target.getFullYear() - 1, 11, 31));
  }

  return weekNumber;
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default PlanningPage;
