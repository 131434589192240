import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { default as React, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelectedDates } from "../../context/SelectedDatesContext";
import { db } from "../../firebase/config";
import { mouseDown } from "../../pages/PlanningPage";

const BeursCard = React.memo(
  ({
    data,
    headerDates,
    rowDates,
    rowDateObjs,
    visible,
    handleScroll,
    globalScroll,
  }) => {
    let opbouwdagenUnlocked = false;
    let afbouwdagenUnlocked = false;
    const { selectedDates } = useSelectedDates();
    const selectedBeursDates = selectedDates[data.inittitle];
    const [loadDateTable, setLoadDateTable] = useState(false);
    const [imageUrl, setImageUrl] = useState(false);

    const reference = useRef(null);
    const projectref = useRef(null);

    const storage = getStorage();

    const navigate = useNavigate();

    const onMouseOver = (rowIndex, dateIndex) => {
      const dateTd = reference.current
        .querySelectorAll("tr")
        [rowIndex + 1].querySelectorAll("td")[dateIndex];

      if (
        (rowIndex == rows.length - 3 && opbouwdagenUnlocked) ||
        (rowIndex == rows.length - 1 && afbouwdagenUnlocked)
      ) {
        if (mouseDown) {
          if (dateTd.getAttribute("background") == undefined) {
            dateTd.setAttribute("background", true);
            if (rowIndex == rows.length - 3) {
              dateTd.style.backgroundColor = "#41dbe9";
            }

            if (rowIndex == rows.length - 1) {
              dateTd.style.backgroundColor = "rgb(185, 65, 233)";
            }
          } else if (dateTd.getAttribute("background") == "false") {
            dateTd.style.backgroundColor = "";
            dateTd.setAttribute("background", "false2");
          }
        }
      }
    };

    const onMouseDown = (rowIndex, dateIndex) => {
      const dateTd = reference.current
        .querySelectorAll("tr")
        [rowIndex + 1].querySelectorAll("td")[dateIndex];

      if (
        (rowIndex == rows.length - 3 && opbouwdagenUnlocked) ||
        (rowIndex == rows.length - 1 && afbouwdagenUnlocked)
      ) {
        if (dateTd.getAttribute("background") == undefined) {
          dateTd.setAttribute("background", true);
          if (rowIndex == rows.length - 3) {
            dateTd.style.backgroundColor = "#41dbe9";
          }

          if (rowIndex == rows.length - 1) {
            dateTd.style.backgroundColor = "rgb(185, 65, 233)";
          }
        } else if (dateTd.getAttribute("background") == "false") {
          dateTd.style.backgroundColor = "";
          dateTd.setAttribute("background", "false2");
        }
      }
    };

    const onMouseOut = (rowIndex, dateIndex) => {
      const dateTd = reference.current
        .querySelectorAll("tr")
        [rowIndex + 1].querySelectorAll("td")[dateIndex];

      if (
        (rowIndex == rows.length - 3 && opbouwdagenUnlocked) ||
        (rowIndex == rows.length - 1 && afbouwdagenUnlocked)
      ) {
        if (dateTd.getAttribute("background") == "true") {
          dateTd.setAttribute("background", false);
        } else if (dateTd.getAttribute("background") == "false2") {
          dateTd.removeAttribute("background");
        }
      }
    };

    const syncSelectedDates = async (type) => {
      let elementIndex = 5;

      if (type === "Afbouwdagen") {
        elementIndex = 7;
      }

      const selectedDates = reference.current.querySelectorAll(
        `tr:nth-child(${elementIndex}) > td[background=false] > tooltip`
      );

      const selectedDatesStrings = Array.from(selectedDates).map(
        (date) => date.textContent
      );

      const selectedDatesRef = doc(db, "snapshots", "selectedDates");

      const key = data["inittitle"];

      const docSnap = await getDoc(selectedDatesRef);
      if (docSnap.exists()) {
        // Document exists
        let docData = docSnap.data();

        // Update the appropriate field without appending, just setting the new values
        if (type === "Opbouwdagen") {
          if (!docData[key]) docData[key] = {};
          docData[key].Opbouwdagen = [...selectedDatesStrings]; // Set new values
        } else if (type === "Afbouwdagen") {
          if (!docData[key]) docData[key] = {};
          docData[key].Afbouwdagen = [...selectedDatesStrings]; // Set new values
        }

        // Assuming you're appending this map to a higher-level array if not already present
        // This step is conceptual, assuming there's an array you're managing to append this data to
        // You would need to fetch the array, update it in your application, and then update the document

        await updateDoc(selectedDatesRef, docData);
      } else {
        // Document does not exist, set it with the initial structure based on the update type
        const dataToSet = {
          [key]:
            type === "Opbouwdagen"
              ? { Opbouwdagen: [...selectedDatesStrings] }
              : { Afbouwdagen: [...selectedDatesStrings] },
        };
        await setDoc(selectedDatesRef, dataToSet);
      }
    };

    const toggleLock = async (type) => {
      if (type === "opbouw") {
        opbouwdagenUnlocked = !opbouwdagenUnlocked;

        if (!opbouwdagenUnlocked) {
          syncSelectedDates("Opbouwdagen");
        }
      } else if (type === "afbouw") {
        afbouwdagenUnlocked = !afbouwdagenUnlocked;

        if (!afbouwdagenUnlocked) {
          syncSelectedDates("Afbouwdagen");
        }
      }

      projectref.current.querySelectorAll("#afbouw-lock")[0].className =
        afbouwdagenUnlocked ? "unlocked" : "";
      projectref.current.querySelectorAll("#opbouw-lock")[0].className =
        opbouwdagenUnlocked ? "unlocked" : "";
    };

    const onScroll = () => {
      if (visible) {
        const element = reference.current;

        const newScrollLeft = element.scrollLeft;

        if (newScrollLeft !== globalScroll) {
          globalScroll = newScrollLeft;
          handleScroll(newScrollLeft);
        }
      }
    };

    function handleNewProjectClick(data) {
      navigate("/nieuwproject", { state: { data } });
    }

    useEffect(() => {
      if (visible) {
        setLoadDateTable(true);

        reference.current.scrollLeft = globalScroll;

        const allTds = reference.current.querySelectorAll(".date-table td");
        allTds.forEach((td) => {
          td.style.display = "table-cell";
        });
      }

      const storageRef = ref(
        storage,
        `gs://beurs-agenda.appspot.com/beurs-images/${data.id}.jpg`
      );

      if (data.img) {
        getDownloadURL(storageRef)
          .then((url) => {
            setImageUrl(url);
          })
          .catch((error) => {
            console.error("Error getting download URL: ", error);
          });
      }
    });

    const hasPrimaryImage =
      data.urlImage && !data.urlImage.includes("unknown.jpg");

    const rows = [
      "Wake-up call",
      "Deadline voorstel",
      "Deadline grafisch",
      "Opbouwdagen",
      "Beursdagen",
      "Afbouwdagen",
    ];

    const startDateObj = formatDMYToDate(data.startdate);
    const endDateObj = formatDMYToDate(data.enddate);

    const datesBetween = getAllDatesBetween(startDateObj, endDateObj);

    // 12 weken wake up call, invullen opbouwdagen en afbouwdagen datums

    let wakeUpCall = new Date(startDateObj);
    wakeUpCall.setDate(startDateObj.getDate() - 84);

    // 8 weken deadline voorstel

    let deadlineVoorstel = new Date(startDateObj);
    deadlineVoorstel.setDate(startDateObj.getDate() - 56);

    // 4 weken deadline grafisch werk

    let deadlineGrafischWerk = new Date(startDateObj);
    deadlineGrafischWerk.setDate(startDateObj.getDate() - 28);

    const currentDayString = getCurrentDateString();

    if (visible) {
      return (
        <project ref={projectref}>
          <card className="visible">
            <img
              src={imageUrl || "/assets/unknown.jpg"}
              alt={data.title}
              id="beurs-image"
              loading="lazy"
              height="144"
              width="144"
            />
            <column>
              <div className="title">{data.title.replaceAll("&amp;", "&")}</div>
              <div className="date">
                {data.startdate === data.enddate
                  ? data.startdate
                  : `${data.startdate.substring(0, 5)} - ${data.enddate}`}
              </div>
              {data.location != "" && data.location != "Niet vermeld" && (
                <div className="location">{data.location}</div>
              )}
              {data.source && <div className="source">{data.source}</div>}
            </column>
            <labels>
              {data.projecten &&
                data.projecten.map((project, index) => (
                  <Link to={`/bewerkproject?projectId=${project.id}`}>
                    <label key={index} className={`status-${project.status}`}>
                      {project.naam || "Empty label"}
                    </label>
                  </Link>
                ))}
              <Link to="/nieuwproject" state={data}>
                <label className="label-add">
                  <img src="/assets/add.svg" alt="Add new project" />
                </label>
              </Link>
            </labels>
            <table>
              <tbody>
                <tr></tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td>Wake-up call</td>
                </tr>
                <tr>
                  <td>Deadline voorstel</td>
                </tr>
                <tr>
                  <td>Deadline grafisch</td>
                </tr>
                <tr>
                  <td>
                    <lock
                      id="opbouw-lock"
                      onClick={() => {
                        toggleLock("opbouw");
                      }}
                    ></lock>
                    Opbouwdagen
                  </td>
                </tr>
                <tr>
                  <td>Beursdagen</td>
                </tr>
                <tr>
                  <td>
                    <lock
                      id="afbouw-lock"
                      onClick={() => {
                        toggleLock("afbouw");
                      }}
                    ></lock>
                    Afbouwdagen
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="date-table">
              <tbody onScroll={onScroll} ref={reference}>
                <tr>
                  {headerDates.map((date, index) => (
                    <td key={index}>{date}</td>
                  ))}
                </tr>

                {Array.from({ length: loadDateTable ? 6 : 0 }, (_, index) => (
                  <tr key={`column-${index}`}>
                    {Array.from({ length: 364 }, (_, index2) => {
                      const currentRow = rows[index];
                      const currentRowDate = rowDates[index2];

                      let color = undefined;
                      let background = undefined;

                      let currentDay = false;

                      if (rowDateObjs[index2] == currentDayString) {
                        currentDay = true;
                      }

                      if (
                        selectedBeursDates != undefined &&
                        selectedBeursDates[currentRow] != undefined &&
                        selectedBeursDates[currentRow].includes(currentRowDate)
                      ) {
                        if (currentRow === "Afbouwdagen") {
                          color = "rgb(185, 65, 233)";
                          background = "false";
                        }
                        if (currentRow === "Opbouwdagen") {
                          color = "#41dbe9";
                          background = "false";
                        }
                      }

                      if (
                        wakeUpCall.toLocaleDateString("en-GB") ==
                          rowDateObjs[index2] &&
                        index == rows.length - 6
                      ) {
                        color = "rgb(114, 114, 255)"; //blue
                      }

                      if (
                        deadlineVoorstel.toLocaleDateString("en-GB") ==
                          rowDateObjs[index2] &&
                        index == rows.length - 5
                      ) {
                        color = "#35b878"; //green
                      }

                      if (
                        deadlineGrafischWerk.toLocaleDateString("en-GB") ==
                          rowDateObjs[index2] &&
                        index == rows.length - 4
                      ) {
                        color = "#ff9545"; // orange
                      }

                      if (
                        datesBetween.includes(rowDateObjs[index2]) &&
                        index == rows.length - 2
                      ) {
                        color = "#fc5a5a"; //red
                      }

                      if (color != undefined) {
                        if (
                          index == rows.length - 3 ||
                          index == rows.length - 1
                        ) {
                          return (
                            <td
                              key={`row-${index2}`}
                              background={background}
                              style={{ backgroundColor: `${color}` }}
                              className={(currentDay && "now") || ""}
                              onMouseOver={() => {
                                onMouseOver(index, index2);
                              }}
                              onMouseDown={() => {
                                onMouseDown(index, index2);
                              }}
                            >
                              <tooltip>{currentRowDate}</tooltip>
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={`row-${index2}`}
                              style={{ backgroundColor: `${color}` }}
                              className={(currentDay && "now") || ""}
                              background={background}
                            >
                              <tooltip>{currentRowDate}</tooltip>
                            </td>
                          );
                        }
                      } else {
                        if (
                          index == rows.length - 3 ||
                          index == rows.length - 1
                        ) {
                          return (
                            <td
                              key={`row-${index2}`}
                              className={(currentDay && "now") || ""}
                              background={background}
                              onMouseOver={() => {
                                onMouseOver(index, index2);
                              }}
                              onMouseDown={() => {
                                onMouseDown(index, index2);
                              }}
                              onMouseOut={() => {
                                onMouseOut(index, index2);
                              }}
                            >
                              <tooltip>{currentRowDate}</tooltip>
                            </td>
                          );
                        } else {
                          return (
                            <td
                              className={(currentDay && "now") || ""}
                              key={`row-${index2}`}
                              background={background}
                            >
                              <tooltip>{currentRowDate}</tooltip>
                            </td>
                          );
                        }
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </card>
        </project>
      );
    } else {
      return (
        <project>
          <card className="hidden"></card>
        </project>
      );
    }
  }
);

function getCurrentDateString() {
  let now = new Date();

  let month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  let day = String(now.getDate()).padStart(2, "0");
  let year = now.getFullYear();

  return `${day}/${month}/${year}`;
}

function getAllDatesBetween(startDate, endDate) {
  const dates = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const formattedDate = currentDate.toLocaleDateString("en-GB"); // Format: dd/mm/yyyy

    dates.push(formattedDate);
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return dates;
}

function formatDMYToDate(dateString) {
  const [day, month, year] = dateString.split("/");
  return new Date(`${year}-${month}-${day}`);
}

export default BeursCard;
