import React, { createContext, useContext, useState } from "react";

const SelectedDatesContext = createContext(null);

export const useSelectedDates = () => useContext(SelectedDatesContext);

export const SelectedDatesProvider = ({ children }) => {
	const [selectedDates, setSelectedDates] = useState([]);

	return (
		<SelectedDatesContext.Provider value={{ selectedDates, setSelectedDates }}>
			{children}
		</SelectedDatesContext.Provider>
	);
};
