import { React, useEffect, useRef } from "react";
import "../../styles/components/header.css";

function CheckBoxRow() {
	const checkBox1 = useRef(null);
	const checkBox2 = useRef(null);
	const checkBox3 = useRef(null);

	const onCheck = (ref) => {
		checkBox1.current.checked = false;
		checkBox2.current.checked = false;
		checkBox3.current.checked = false;

		ref.current.checked = true;
	};

	useEffect(() => {
		if (checkBox2.current.checked) {
			checkBox1.current.checked = false;
			checkBox3.current.checked = false;
		}
		if (checkBox3.current.checked) {
			checkBox1.current.checked = false;
			checkBox2.current.checked = false;
		}
	});

	return (
		<td>
			<row>
				<input
					ref={checkBox1}
					onClick={() => {
						onCheck(checkBox1);
					}}
					value="Ja"
					type="checkbox"
					checked="true"
				></input>
				<span class="checkbox"></span>
				<input
					ref={checkBox2}
					onClick={() => {
						onCheck(checkBox2);
					}}
					value="Nee"
					type="checkbox"
				></input>
				<span class="checkbox"></span>
				<input
					ref={checkBox3}
					onClick={() => {
						onCheck(checkBox3);
					}}
					value="Nee"
					type="checkbox"
				></input>
				<span class="checkbox"></span>
			</row>
		</td>
	);
}

export default CheckBoxRow;
